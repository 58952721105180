<template>
  <div class="wrapper">
    <admin-title title="订单列表"></admin-title>
    <el-form class="search" ref="form" :model="searchData" inline label-width="80px">
      <el-form-item label="订单号">
        <el-input style="width: 200px" v-model="searchData.orderNo" placeholder="请输入" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="用户类型">
        <el-select style="width: 200px" v-model="searchData.memberType" placeholder="请选择" size="mini" clearable>
          <el-option label="新客" :value="1"> </el-option>
          <el-option label="在租" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-select style="width: 200px" v-model="searchData.orderType" placeholder="请选择" size="mini" clearable>
          <el-option label="普通订单" :value="1"> </el-option>
          <el-option label="门店订单" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否监管">
        <el-select style="width: 200px" v-model="searchData.enableAbm" placeholder="请选择" size="mini" clearable>
          <el-option label="是" :value="true"> </el-option>
          <el-option label="否" :value="false"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否免押">
        <el-select style="width: 200px" v-model="searchData.enableAlipayFundAuth" placeholder="请选择" size="mini" clearable>
          <el-option label="是" :value="true"> </el-option>
          <el-option label="否" :value="false"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-select style="width: 200px" v-model="searchData.orderStatusList" multiple placeholder="请选择" size="mini" clearable>
          <el-option label="使用中" :value="6"> </el-option>
          <el-option label="逾期中" :value="7"> </el-option>
          <el-option label="待买断" :value="8"> </el-option>
          <el-option label="已完成" :value="91"> </el-option>
          <el-option label="已取消" :value="92"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList()">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="" label="用户信息" min-width="180">
        <template slot-scope="{ row }">
          <div v-if="row.member">
            <div>姓名：{{ row.member.memberName }}</div>
            <div>手机号：{{ row.member.mobile }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="商品信息" min-width="180">
        <template slot-scope="{ row }">
          <div>
            <div>{{ row['goodsTitle'] }}</div>
            <div>
              是否监管：
              <el-tag :type="row['enableAbm'] ? 'primary' : 'warning'" size="mini">
                {{ row['enableAbm'] ? '是' : '否' }}
              </el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="订单信息" min-width="270">
        <template slot-scope="{ row }">
          <div>
            <div>订单单号：{{ row['orderNo'] }}</div>
            <div>下单时间：{{ row['orderCreateTime'] }}</div>
            <div>
              订单状态：
              <el-tag v-if="row.orderStatus == 6" type="primary" size="mini">
                {{ row['orderStatusName'] }}
              </el-tag>
              <el-tag v-if="row.orderStatus == 7" type="warning" size="mini">
                {{ row['orderStatusName'] }}
              </el-tag>
              <el-tag v-if="row.orderStatus == 8" type="info" size="mini">
                {{ row['orderStatusName'] }}
              </el-tag>
              <el-tag v-if="row.orderStatus == 91" type="success" size="mini">
                {{ row['orderStatusName'] }}
              </el-tag>
              <el-tag v-if="row.orderStatus == 92" type="danger" size="mini">
                {{ row['orderStatusName'] }}
              </el-tag>
            </div>
            <div>
              订单类型：
              <el-tag v-show="row['orderType'] == 1" type="primary" size="mini">
                普通订单
              </el-tag>
              <el-tag v-show="row['orderType'] == 2" type="warning" size="mini">
                门店订单
              </el-tag>
              <el-tag v-show="row['orderType'] == 3" type="success" size="mini">
                卡券订单
              </el-tag>
              <el-tag v-show="row['enableAlipayFundAuth'] == true" type="danger" size="mini" style="margin-left: 5px">
                免押
              </el-tag>
            </div>

            <div>下单方式：{{ row['clientTypeName'] }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="还款计划" min-width="190">
        <template slot-scope="{ row }">
          <div v-if="row.member">
            <div>剩余待收期数：{{ row.leftDueNum }}</div>
            <div>最近账单日：{{ row.latestDueDate }}</div>
            <div>最近待收金额：{{ row.latestDueAmount }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="acquireAmount" label="订单价值" min-width="100"> </el-table-column>
      <el-table-column prop="totalDueAmount" label="待收金额" min-width="100"> </el-table-column>
      <el-table-column prop="recvDueAmount" label="已回收金额" min-width="120"> </el-table-column>
      <el-table-column prop="leftDueAmount" label="剩余待收金额" min-width="120"> </el-table-column>
      <el-table-column prop="estProfitAmount" label="预估收益" min-width="100"> </el-table-column>
      <el-table-column prop="estLlr" label="预估年化收益率" min-width="120"> </el-table-column>
      <el-table-column prop="" label="操作" min-width="110" fixed="right" width="120">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" :underline="false" type="primary" @click="handleDetail(row)">详情</el-link>
          <el-link :underline="false" type="primary" @click="handleBill(row)">账单</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />
    <div style="height:100px"></div>
  </div>
</template>

<script>
import { getListAPI } from './api'
export default {
  name: 'List',

  data() {
    return {
      searchData: {
        page: 1,
        pageSize: 10,
        orderNo: '',
        memberType: '', //用户类型：客户类型：1-新客 2-在租
        orderType: '', //订单类型：1-普通订单 2-门店订单
        enableAbm: '', //boolean
        enableAlipayFundAuth: '',
        orderStatusList: []
      },
      list: [],
      total: 0
    }
  },

  mounted() {
    window['getBpageList'] = () => {
      this.getList()
    }
  },
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const { page, pageSize, orderNo, memberType, orderType, enableAbm, enableAlipayFundAuth, orderStatusList } = this.searchData
      let params = { page, pageSize, orderNo, memberType, orderType, enableAbm, enableAlipayFundAuth }
      if (orderStatusList.length > 0) {
        params.orderStatusList = orderStatusList.join(',')
      }

      const res = await getListAPI(params)
      this.list = res.list
      this.total = res.total
    },
    // 详情
    handleDetail(row) {
      let link = this.$router.resolve({
        name: `OrderDetail`,
        params: { id: row['orderNo'] },
        query: { memberId: row['member']['id'] }
      })
      window.open(link.href, '_blank')
    },
    // 账单
    handleBill(row) {
      let link = this.$router.resolve({ name: `OrderBill`, params: { id: row['orderNo'] } })
      window.open(link.href, '_blank')
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .search {
    .el-form-item {
      margin-bottom: 10px;
      margin-right: 15px;
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
}
</style>
